import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { find } from 'lodash'
import { Form as FormProvider, Field } from 'react-final-form'
import { Comment, Form, Button, Modal, Icon, Tooltip } from 'antd'
import { MentionsInput, Mention } from 'react-mentions'
import { animateScroll } from "react-scroll"
import { Module } from 'Traqa/Packages'
import { getComments, createComment, deleteComment, likeComment, unlikeComment } from '../../Api/comments'
import { getUsers } from '../../Api/users'
import Avatar from './Components/Avatar'
import { getUser, isAdmin } from 'Traqa/Helpers'
import styles from './Comments.css'

export default class Comments extends React.PureComponent {

  state = {
    comments: [],
  }

  static propTypes = {
    modelType: PropTypes.string.isRequired,
    modelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    useHeader: PropTypes.bool,
  }

  static defaultProps = {
    useHeader: true,
  }

  componentDidMount () {
    this.fetch()
    this.scrollToBottom()
  }

  componentDidUpdate () {
    this.scrollToBottom()
  }

  fetch = async () => {

    let response = await getComments({
      params: {
        'filter[model_type]': this.props.modelType,
        'filter[model_id]': this.props.modelId,
        include: 'user,comment_likes.user',
      }
    })

    this.setState({ comments: response.data.data })

    response = await getUsers({ params: { 'filter[team_id]': 1, sort: 'name' } })

    this.setState({ users: response.data.data });
  }

  onSubmit = async (values, form) => {
    
    await createComment({
      data: {
        model_type: this.props.modelType,
        model_id: this.props.modelId,
        content: values.content,
      }
    })

    await this.fetch()

    setTimeout(form.reset)
  }

  onLike = async (comment) => {

    await likeComment({
      id: comment.id
    })

    await this.fetch()
    
  }

  onUnlike = async (comment) => {

    await unlikeComment({
      id: comment.id
    })

    await this.fetch()
    
  }

  onDelete = (comment) => {

    const onOk = async () => {

      await deleteComment({
        id: comment.id
      })

      await this.fetch()

    }

    Modal.confirm({
      title: 'Delete Comment?',
      content: 'This action cannot be undone.',
      icon: <Icon type="delete" style={{ color: '#FF4D4F' }} />,
      onOk,
      okType: 'danger',
      okText: 'Delete'
   })
    
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToBottom() {
    animateScroll.scrollToBottom({
      containerId: "scroll",
    })
  }

  renderActions (comment) {
    
    const userIds = comment.comment_likes.map(commentLike => commentLike.user_id)

    const user = getUser()

    const actions = []

    if (userIds.includes(user.id)) {
      actions.push(
        <span key="comment-like" onClick={() => this.onUnlike(comment)}>
          <Icon
            type="like"
            theme="filled"
          />
          {' Liked'}
        </span>,
      )
    } else {
      actions.push(
        <span key="comment-like" onClick={() => this.onLike(comment)}>
          <Icon
            type="like"
            theme="outlined"
          />
          {' Like'}
        </span>,
      )
    }

    if (isAdmin()) {
      actions.push(
        <span key="comment-delete" onClick={() => this.onDelete(comment)}>
          <Icon
            type="delete"
          />
          {' Delete'}
        </span>
      )
    }

    if (comment.comment_likes.length) {
      actions.push(
        <span style={{ fontStyle: 'italic', cursor: 'default' }}>
          {`Like by: `}
          {comment.comment_likes.map(commentLike => commentLike.user.name).join(', ')}
        </span>
      )
    }
    
    return actions

  }

  render () {

    const user = getUser()

    const {
      comments,
    } = this.state
    
    const {
      useHeader,
    } = this.props
    
    return (

      <Module>

        {useHeader && <Module.Header title="Comments" />}

        <Module.Section contentStyle={{ padding: 30, paddingLeft: 30, paddingRight: 30, paddingBottom: 10 }}>

          <div id='scroll' style={{ height: 250, overflowY: 'scroll' }}>

            {comments.map(comment => {

              return (

                <Comment
                  avatar={(<Avatar user={comment.user} />)}
                  key={comment.id}
                  author={`${comment.user.first_name} ${comment.user.last_name}`}
                  content={(
                    <p>
                      {comment.content.split(' ').map((word, i) => {
                        if (/^@\[.*?\]\(.*?\)$/.test(word)) {
                          const username = word.match(/^@\[(.*?)\]\(.*?\)$/)[1]
                          const userId = word.match(/^@\[.*?\]\((.*?)\)$/)[1]
                          const mentionedUser = find(this.state.users, { id: Number(userId) })
                          return (
                            <Tooltip key={i} title={mentionedUser ? mentionedUser.name : username}>
                              <span style={{ color: '#1990FF' }}>{`@${username}`}</span>
                            </Tooltip>
                          )
                        }
                        return word
                      }).reduce((prev, curr) => [prev, ' ', curr])}
                    </p>
                  )}
                  datetime={(<span>{`${moment(comment.created_at).format('ddd Do MMM YYYY [at] h:mma')} (${moment(comment.created_at).fromNow()})`}</span>)}
                  actions={this.renderActions(comment)}
                />

              )

            })}
            
          </div>

          <Comment
            avatar={(<Avatar user={user} />)}
            content={(

              <FormProvider onSubmit={this.onSubmit}>
                {({ handleSubmit, submitting, pristine }) => (

                  <Form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                  >

                    <Field name="content">
                      {({ input }) => {

                        return (
                          <MentionsInput value={input.value} onChange={input.onChange} className="mentions-input">
                            <Mention
                              trigger="@"
                              data={(search) => {
                                const users = this.state.users
                                  .map(u => ({ id: u.id, display: `${u.username}`}))
                                  .filter(u => u.display.toLowerCase().includes(search.toLowerCase()))
                                return users
                              }}
                              displayTransform={(id, display) => `@${display}`}
                              appendSpaceOnAdd
                              allowSuggestionsAboveCursor
                            />
                          </MentionsInput>
                        )

                      }}
                    </Field>

                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={handleSubmit}
                      loading={submitting}
                      disabled={pristine || submitting}
                      className={styles.submitButton}
                    >
                      Add Comment
                    </Button>

                  </Form>
                
                )}
              
              </FormProvider>

            )}
          />

        </Module.Section>

      </Module>

    )
  }
}